/**
 * This plugin create an axios HTTP client to do request.
 * It handles tokens too to acess to private routes on API.
 */

import axios from 'axios';
import swal from 'sweetalert2';

export default {
  beforeCreate(context, inject) {
    // eslint-disable-next-line no-unused-vars
    const { error, req, store } = context;

    const apiUrl =
      process.env.VUE_APP_MODE === 'production'
        ? process.env.VUE_APP_PROD_API_URL
        : process.env.VUE_APP_LOCAL_API_URL;
    // const apiUrl = process.env.API_URL || 'http://localhost:8080';
    axios.defaults.headers.common['Content-Language'] = store.state.global.locale;
    if (store.state.global.token) {
      axios.defaults.headers.common.Authorization = `Bearer ${store.state.global.token}`;
    }
    axios.defaults.headers.common['time-zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // console.log('CONTEXTTTT:',context);
    // console.log('CONTEXTTTT APP:',context.app);
    // Create axios client
    const httpClient = axios.create({
      // Change API url: depends on server side or client side
      baseURL: apiUrl,
    });

    // // Use request interceptors
    // httpClient.interceptors.request.use(config => {
    //   let token = null;
    //
    //   // Get current token in cookies
    //   if (process.server) {
    //     token = req.cookies.token;
    //   } else {
    //     token = require('js-cookie').get('token');
    //   }
    //
    //   // If token: add header
    //   if (token) {
    //     config.headers['Authorization'] = `Bearer ${token}`;
    //   }
    //
    //   return config;
    // });

    // Use response interceptor
    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        // if (err.response) {
        //   const { data } = err.response;
        //   // Catch error and use UVue error handler plugin to display it
        //   return error(data.error || 'Oups!', err.response.status);
        // }
        // return Promise.reject(err);
        console.log('ERROR HTTP:', error);
        const { status } = error.response || {};
        if (status >= 500) {
          swal({
            type: 'error',
            title: 'Oops...',
            text: error.response.data.error,
            reverseButtons: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'CANCEL',
          });
        } else if (status === 470) {
          // validation exception
          const errors = error.response.data.errors;
          store.commit('error/SET_ERROR', errors);
          // eslint-disable-next-line no-empty
        } else if (status === 460) {
          // For not showing anything when returning error code
        } else if (status === 401 && store.getters.isLoggedIn) {
          swal({
            type: 'warning',
            title: 'Session Expired',
            text: 'Please Login to continue',
            reverseButtons: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'CANCEL',
          }).then(() => {
            store.dispatch('logout');

            context.router.push('/login');
          });
        } else if (status === 462) {
          let message = error.response.data.errors;
          let requirements = error.response.data.requirements;
          if (requirements && requirements.length > 0) {
            message = `<div>${error.response.data.errors}</div>`;
            for (let req of requirements) {
              message += `<div>- ${req}</div>`;
            }
          }
          swal({
            type: 'error',
            title: 'Kode Promo Tidak Dapat Digunakan',
            html: message,
            reverseButtons: true,
            confirmButtonText: 'OK',
          });
        } else {
          if (error.response.data.errors) {
            if (error.response.data.error_code) {
              let title = '';
              let message = '';
              switch (error.response.data.error_code) {
                case 1001:
                  title = 'Koin tidak mencukupi';
                  message = `Jumlah <b>Koin</b> Anda <b>${error.response.data.coins_remaining}</b>.<br>Aktivasi listing membutuhkan <b>${error.response.data.required_coins} Koin</b>.`;
                  break;
                case 1002:
                  title = 'Membership tidak aktif';
                  message = `Silahkan aktifkan membership untuk aktivasi listing.`;
                  break;
                case 1003:
                  title = 'Listing slot penuh';
                  message = `Tidak bisa aktivasi listing karena saat ini listing aktif Anda sudah penuh, yaitu <b>${error.response.data.listing_slot} listing</b>.`;
                  break;
                case 1004:
                  title = 'Koin tidak mencukupi';
                  message = `Jumlah <b>Koin</b> Anda <b>${error.response.data.coins_remaining}</b>.<br>Repost listing membutuhkan <b>${error.response.data.required_coins} Koin</b>.`;
                  break;
                case 1005:
                  title = 'Membership tidak aktif';
                  message = `Silahkan aktifkan membership untuk repost listing.`;
                  break;
                case 1006:
                  title = 'Listing slot penuh';
                  message = `Tidak bisa repost listing karena saat ini listing aktif Anda sudah penuh, yaitu <b>${error.response.data.listing_slot} listing</b>.`;
                  break;
                case 1007:
                  title = 'Koin tidak mencukupi';
                  message = `Jumlah <b>Koin</b> Anda <b>${error.response.data.coins_remaining}</b>.<br>Beli slot agen pilihan membutuhkan <b>${error.response.data.required_coins} Koin</b>.`;
                  break;
                case 1008:
                  title = 'Membership tidak aktif';
                  message = `Silahkan aktifkan membership untuk beli slot agen pilihan.`;
                  break;
              }
              swal({
                type: 'error',
                title: title,
                html: message,
                reverseButtons: true,
                confirmButtonText: 'OK',
              });
            } else {
              swal({
                type: 'error',
                title: 'Oops...',
                text: error.response.data.errors,
                reverseButtons: true,
                confirmButtonText: 'OK',
                cancelButtonText: 'CANCEL',
              });
            }
          }
        }

        return Promise.reject(error);
      },
    );

    // Inject httpClient eveywhere
    inject('http', httpClient);

    // You can use it everywhere in your app:
    // - In UVue context: `context.$http.get(...)`
    // - In your components: `this.$http.get(...)`
    // - In your store actions: `this.$http.get(...)`
  },
};
