export const state = () => ({
  loading: false,
  totalCoins: 0,
  categories: [
    {
      id: 1,
      name: 'Aktif',
      value: 'active',
    },
    {
      id: 2,
      name: 'Kadaluarsa',
      value: 'expired',
    },
  ],
  category: {
    id: 1,
    name: 'Aktif',
    value: 'active',
  },
  purchases: [],
  purchasesMeta: null,
  keyword: null,
  page: 1,
  usages: [],
  usagesMeta: null,
});

export const getters = {};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_TOTAL_COINS(state, payload) {
    state.totalCoins = payload;
  },
  SET_PURCHASES(state, payload) {
    state.purchases = payload;
  },
  SET_PURCHASES_META(state, payload) {
    state.purchasesMeta = payload;
  },
  SET_KEYWORD(state, payload) {
    state.keyword = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_USAGES(state, payload) {
    state.usages = payload;
  },
  SET_USAGES_META(state, payload) {
    state.usagesMeta = payload;
  },
  SET_CATEGORY(state, payload) {
    state.category = payload;
  },
};

export const actions = {
  async getTotalCoins({ commit }) {
    try {
      commit('SET_LOADING', true);
      const { data } = await this.$http.get('/api/coins/total');
      commit('SET_TOTAL_COINS', data.data);
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getAllPurchases({ state, commit }, page) {
    try {
      commit('SET_LOADING', true);
      if (page) {
        commit('SET_PAGE', page);
      }
      let { data } = await this.$http.get('/api/coins/purchases', {
        params: {
          category: state.category ? state.category.value : null,
          page: state.page,
        },
      });
      commit('SET_PURCHASES', data.data);
      commit('SET_PURCHASES_META', data.meta);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async restoreInitiateUsages({ commit }) {
    commit('SET_USAGES', []);
    commit('SET_USAGES_META', null);
  },
  // eslint-disable-next-line no-unused-vars
  async getAllUsages({ state, commit }, page) {
    try {
      commit('SET_LOADING', true);
      if (page) {
        commit('SET_PAGE', page);
      }
      let { data } = await this.$http.get('/api/v2/coins/usages', {
        params: {
          q: state.keyword,
          page: state.page,
        },
      });
      commit('SET_USAGES', data.data);
      commit('SET_USAGES_META', data.meta);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
