export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  async getProvinces({}) {
    let response = await this.$http.get('/api/regional/get_provinces');

    return response.data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async getCitiesByProvinceId({}, province_id) {
    let response = await this.$http.get('/api/regional/get-cities-by-province', {
      params: {
        province_id: province_id,
      },
    });

    return response.data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async getVillagesByDistrictId({}, district_id) {
    let response = await this.$http.get('/api/regional/get-villages-by-district', {
      params: {
        district_id: district_id,
      },
    });

    return response.data.data;
  },
  // eslint-disable-next-line no-empty-pattern
  async getAreasByCityId({}, city_id) {
    let response = await this.$http.get('/api/regional/get-areas-by-city', {
      params: {
        city_id: city_id,
      },
    });

    return response.data.data;
  },
};
