export const state = () => ({
  loading: false,
  cardNumber: '',
  cardHolder: '',
  cardExpiry: '',
  cardCvv: '',
  cardToken: '',
  midtransMode: '',

  duration: null,
  directActivation: null,
  price: null,
});

export const getters = {};

export const mutations = {
  SET_DURATION(state, payload) {
    state.duration = payload;
  },
  SET_DIRECT_ACTIVATION(state, payload) {
    state.directActivation = payload;
  },
  SET_PRICE(state, payload) {
    state.price = payload;
  },

  SET_MIDTRANS_MODE(state, payload) {
    state.midtransMode = payload;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_CARD_NUMBER(state, payload) {
    state.cardNumber = payload;
  },
  SET_CARD_HOLDER(state, payload) {
    state.cardHolder = payload;
  },
  SET_CARD_EXPIRY(state, payload) {
    state.cardExpiry = payload;
  },
  SET_CARD_CVV(state, payload) {
    state.cardCvv = payload;
  },
  SET_CARD_TOKEN(state, payload) {
    state.cardToken = payload;
  },
};

export const actions = {
  resetCardInfo({ commit }) {
    commit('SET_CARD_NUMBER', '');
    commit('SET_CARD_HOLDER', '');
    commit('SET_CARD_EXPIRY', '');
    commit('SET_CARD_CVV', '');
    commit('SET_CARD_TOKEN', '');

    commit('SET_DURATION', null);
    commit('SET_DIRECT_ACTIVATION', null);
    commit('SET_PRICE', null);
  },
};
