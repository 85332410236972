export const state = () => ({
  loading: false,
  searchQuery: null,
  page: 1,
  featuredAgents: [],
  meta: null,
  availabilities: [],
  areaList: [],
  area: null,
  selectedAvailabilities: [],
  cost: 0,
});

export const getters = {
  formattedFeaturedAgents(state) {
    if (state.featuredAgents && state.featuredAgents.length > 0) {
      const grouped = state.featuredAgents.reduce((acc, curr) => {
        const key = curr.area_id; // group by area_id (and area is assumed to be same for same id)
        if (!acc[key]) {
          acc[key] = {
            area_id: curr.area_id,
            area: curr.area,
            entries: [],
          };
        }
        // Destructure the properties needed for the entry
        const { uuid, month_name, month, year } = curr;
        acc[key].entries.push({ uuid, month_name, month, year });
        return acc;
      }, {});

      // Sort entries by year and month in ascending order
      return Object.values(grouped).map((group) => {
        group.entries.sort((a, b) => a.year - b.year || a.month - b.month);
        return group;
      });
    }

    return [];
  },
};

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_SEARCH_QUERY(state, payload) {
    state.searchQuery = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_FEATURED_AGENTS(state, payload) {
    state.featuredAgents = payload;
  },
  SET_META(state, payload) {
    state.meta = payload;
  },
  SET_AVAILABILITIES(state, payload) {
    state.availabilities = payload;
  },
  SET_AREA_LIST(state, payload) {
    state.areaList = payload;
  },
  SET_AREA(state, payload) {
    state.area = payload;
  },
  SET_SELECTED_AVAILABILITIES(state, payload) {
    state.selectedAvailabilities = payload;
  },
  SET_COST(state, payload) {
    state.cost = payload;
  },
};

export const actions = {
  restoreInitialState({ commit }) {
    commit('SET_SEARCH_QUERY', null);
    commit('SET_PAGE', 1);
    commit('SET_FEATURED_AGENTS', []);
    commit('SET_META', null);
    commit('SET_AVAILABILITIES', []);
    commit('SET_AREA_LIST', []);
    commit('SET_AREA', null);
    commit('SET_SELECTED_AVAILABILITIES', []);
    commit('SET_COST', 0);
  },
  async getAreas({ commit }, query) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/regional/get_areas', {
        params: {
          query: query,
        },
      });
      commit('SET_AREA_LIST', data.data);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getPurchasedFeaturedAgents({ state, commit }, { page, useLoading = true }) {
    try {
      commit('SET_LOADING', useLoading);
      if (page) {
        commit('SET_PAGE', page);
      }
      let { data } = await this.$http.get('/api/featured_agents/purchased', {
        params: {
          page: state.page,
          area: state.searchQuery,
          per_page: 30,
        },
      });
      commit('SET_FEATURED_AGENTS', data.data);
      commit('SET_META', data.meta);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getAreaAvailabilities({ state, commit }) {
    try {
      commit('SET_LOADING', true);
      if (state.area) {
        let { data } = await this.$http.get('/api/featured_agents/availability', {
          params: {
            area_id: state.area ? state.area.id : undefined,
          },
        });
        commit('SET_AVAILABILITIES', data.data);
        return data.data;
      } else {
        commit('SET_AVAILABILITIES', []);
        return [];
      }
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async buyFeaturedAgent({ state, commit }) {
    try {
      commit('SET_LOADING', true);
      return await this.$http.post('/api/featured_agents/buy', {
        area_id: state.area ? state.area.id : undefined,
        availabilities: state.selectedAvailabilities.map((availability) => ({
          month: availability.month,
          year: availability.year,
        })),
      });
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getCost({ commit }, query) {
    try {
      commit('SET_LOADING', true);
      let { data } = await this.$http.get('/api/featured_agents/cost', {
        params: {
          query: query,
        },
      });
      commit('SET_COST', data.data.cost);
      return data.data;
    } catch (e) {
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
